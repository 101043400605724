<template>
  <v-app>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center" v-if="!loadMore">
          
          <v-card class="mx-n4" elevation="15" width="350" height="278px" style="background-color:#CFD8DC">
            <img src="../images/gc_tsuika_logo.png" alt="" height="100px" style="margin-left:25%; margin-top: 20%;">
          
            <h5 style="text-align:center; margin-top:2px"><b>{{ $t("login.additional_ordering_system")}}</b></h5>            
          </v-card>


            <v-card elevation="5" width="350">
                <template slot="progress">
                    <v-progress-linear  
                        color="black"
                        height="5"
                        indeterminate
                    ></v-progress-linear>
                </template>
                <v-card-title class="justify-left" style="height: 70px;">
                  <!-- <img src="../images/icon.png" height="45px" alt="">
                  <span 
                    style="margin-left: 8px; color: #1d3244;
                    font-weight: bolder;
                    color: #274761;"
                    >
                      追加発注システム
                    </span>
                    <v-spacer></v-spacer> -->
                    <span style="font-size: 15px; color: #274761;">{{ $t("login.version_login")}} {{ versionNumber }}</span>
                    <v-spacer></v-spacer>
                    <span style="font-size: 10px; color: #274761;">{{ $t("login.system_login")}}</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <!-- <v-form  id="LoginForm" ref="LoginForm" v-on:submit.prevent="submitLogin"> -->
                    <v-form @submit.prevent="login()">
                        <v-text-field
                            v-model="loginData.email"
                            :rules="rules.required.concat(rules.email)"
                            name="employee_no"
                            :label="$t('login.username_login')"
                            dense
                            outlined
                            autocomplete="off"
                        >
                        <v-icon slot="prepend" color="#274761">
                            mdi-account-circle
                        </v-icon>
                        </v-text-field>
                        <v-text-field
                            v-model="loginData.password"
                            :rules="rules.required"
                            name="password"
                            :label="$t('login.pasword_login')"
                            dense
                            outlined
                            autocomplete="off"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPassword = !showPassword"
                        >
                          <v-icon slot="prepend" color="#274761">
                              mdi-lock
                          </v-icon>
                        </v-text-field>
                        <v-divider></v-divider>
                        <v-card-actions :class="{ 'pt-3 pl-3 pr-3 pb-0': $vuetify.breakpoint.smAndUp }">
                            <!-- <a
                              style="font-size: 9px !important;" 
                              href="https://s3.ap-southeast-1.amazonaws.com/gctsuika.hrd-s.com/user_manual/HRDUserManual.pdf" target="_blank">
                              {{ $t("login.manualhrd_login")}}   |
                            </a>
                            <a
                              style="font-size: 9px !important; margin-left: 4px !important;" 
                              href="https://s3.ap-southeast-1.amazonaws.com/gctsuika.hrd-s.com/user_manual/GCUserManual.pdf" target="_blank">
                              {{ $t("login.manualGc_login")}}
                            </a> -->

                            <div>
                          <!-- <span 
                            style="font-size: 12px !important;
                                  font-weight: 400;
                                  font-family: Roboto,sans-serif!important;
                                  margin-bottom: 5px !important;
                                  padding-left: 10px !important;"
                          >Language:</span> -->
                          <span 
                            style="font-size: 9px !important;
                                  font-weight: 400;
                                  font-family: Roboto,sans-serif!important;
                                  margin-bottom: 5px !important;
                                  padding-left: 10px !important;"
                          > {{ $t("login.language")}} :</span>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="custom-btn"
                                :ripple="false"
                                v-bind="attrs"
                                v-on="on"
                                text
                                small
                                style="margin-top: 1px; margin-left: -7px !important;font-size: 9px !important;"
                              >
                                {{ $t("lang") }}
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(item, index) in Language"
                                :key="index"
                                @change="LanguageSwitcher(item.lang)"
                                item-value="lang"
                              >
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                            
                          </v-menu>
                        </div>
                            <v-spacer></v-spacer>
                            <v-btn :disabled="tsuikaUserData.length > 0 ? false : true" small type="submit" style="color: #fff; background-color: #1d3244">
                              {{ $t("login.btn_login")}}
                            </v-btn>  


                            
                        </v-card-actions>
              
           
                    </v-form>
                </v-card-text>
            </v-card>
        </v-row>

        <v-overlay :value="loadMore" overlay>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-container>
    </v-main>
  </v-app>
</template>
<script>
// import router from '../router/index'
import md5 from 'md5';
// import axios from "axios";
import swal from "sweetalert2";
import { mapState, mapActions } from "vuex";

export default {
  name: "App",
  data() {
    return {
      loginData: {},
      title: "GC Tsuika Hacchu System",
      showPassword: "",
      userData: [],
      localUserData: [],
      loadMore: false,
      withoutAccess: false,
      // rules: {
      //     required: value => !!value || 'Field is required',
      //     counter: value => value.length <= 20 || 'Max 20 characters',
      //     email: value => {
      //       const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      //       return pattern.test(value) || 'Invalid e-mail.'
      //     },
      //   },
      Language: [
        { title: "English", lang: "en" },
        { title: "Japanese", lang: "ja" },
      ],
    };
  },
  computed: {
    ...mapState(['accounts', 'accessRightData', 'userNumberData', 'tsuikaUserData', 'fullAdminData', 'rules','versionData']),
    versionNumber(){
      const firstVersion = this.versionData.find(item => item.version);
      return firstVersion ? firstVersion.version : '';
    },
    
  },
  methods: {
    ...mapActions(['getAccessRights', 'getUserNumberData', 'getTsuikaUsers', 'getFullAdmin', 'getSubGroup','getVersion']),
    // async getTsuikaUsers() {
    //   await axios.get(`http://10.169.140.119:3001/users/getTsuikaUsers`).then(res => {
    //     if(res.data) {
    //       this.tsuikaUserData = res.data;
    //     }
    //   })
    // },
    
    // ANCHOR -- LOGIN FUNCTION
    LanguageSwitcher(lang) {
      this.$i18n.locale = lang;
      // this.getTranslate(lang);
    },
    login() {
      if(!this.loginData.email || !this.loginData.password) {
        swal.fire({
          toast: true,
          position: "center",
          icon: "error",
          title: `Complete fields`,
          showConfirmButton: false,
          timer: 1500,
        });
        return false;
      } else {
        let email = this.loginData.email.trim(); 
        let findUser = this.tsuikaUserData.find(user => user.MailAddress == email); //for actual

        if(!findUser) {
          swal.fire({
            toast: true,
            position: "center-end",
            icon: "error",
            title: `Email doesn't exists`,
            showConfirmButton: false,
            timer: 1500,
          });
          return false;
        }

        if(findUser) {
          
          if(!Object.hasOwn(findUser, 'isGCTsuika')) {
            swal.fire({
              toast: true,
              position: "center",
              icon: "error",
              title: `You don't have access to GC Tsuika.`,
              showConfirmButton: false,
              timer: 1500,
            });
          }

          if(Object.hasOwn(findUser, 'isGCTsuika')) {
            
            if(!findUser.isGCTsuika) {
              swal.fire({
                toast: true,
                position: "center",
                icon: "error",
                title: `You don't have access to GC Tsuika.`,
                showConfirmButton: false,
                timer: 1500,
              });
            }

            if(findUser.isGCTsuika) {

              if(findUser.account_status != "Active") {
                swal.fire({
                  title: "Inactive Account",
                  text: "Please Contact System Admin to Confirm your Account. Thankyou.",
                  icon: "error",
                });
              }

              if(findUser.account_status == "Active") {
                if(findUser.Password != md5(this.loginData.password)) {
                  swal.fire({
                    toast: true,
                    position: "center-end",
                    icon: "error",
                    title: `Incorrect password. Please check you password.`,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
                else {
                  const findUserNumber = this.userNumberData.find(userNumber => userNumber.user_id == findUser.UserId);
                  const isFullAdmin = this.fullAdminData.some(fullAdmin => fullAdmin.user_id == findUser.UserId);
                  const homePage = [2]; //meta id for home page
                  const industryCategory = [5];  //meta id for industry category page
                  const orderHistory = [12];  //meta id for industry category page //REVIEW - for hide orderHistory
                  let findAccessRights = [];  //an array for access rightszz
                  // const gcLeader = [6];  //meta id for gc form page
                  let emailPage = [3]
                  let industryPage =[4]

                  if(!isFullAdmin) {
                    //setting the access rights if the user is not full admin
                    findAccessRights = this.accessRightData.filter(accessRight => {
                      if(accessRight.access_right != 3) {
                        return accessRight.account_type == findUser.account_type && accessRight.sub_group == findUser.gc_subgroup
                      }
                    }).map(m => m.master_id);
                    // show only order history for this group ANCHOR - //REVIEW - for hide orderHistory
                    // let rights = ["Nihon Sangyou", "GC Leader", "GC Member"]
                    // let newUser = ["nissan-gc@kk-nissan.co.jp"];
                    // if(rights.includes(findUser.account_type) || newUser.includes(findUser.MailAddress)) {
                    // if(rights.includes(findUser.account_type)) {
                      // findAccessRights = findAccessRights
                    // }
                    //show only order history for this group //REVIEW - for hide orderHistory
                    let rights = ["GC Leader", "GC Member"]
                    let newUser = ["nissan-gc@kk-nissan.co.jp"];
                    if(rights.includes(findUser.account_type) || newUser.includes(findUser.MailAddress)) {
                      findAccessRights = findAccessRights.concat(orderHistory,emailPage,industryPage)
                    }


                  }
                  else {
                    findAccessRights = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12] //for restriction in full admin with order history page//REVIEW - for hide orderHistory
                    // findAccessRights = [3, 4, 5, 6, 7, 8, 9, 10, 11] //for restriction in full admin
                  }

                  if(findAccessRights.includes(4)) {
                    findAccessRights = findAccessRights.concat(industryCategory)
                  }

                  // if(findUser.account_type == 'GC Member' || findUser.account_type == 'GC Leader') {
                  //   if(!findAccessRights.includes(6)) {  // 6 is a meta id for gc form page
                  //    findAccessRights = findAccessRights.concat(gcLeader); //adding meta id in access rights array
                  //   }
                  // }


                  /* loggedInData that will pass in local storage for user */

                  var loggedInData = {
                    user_id: findUser.UserId,
                    user_name: findUser.Name,
                    email: findUser.MailAddress,
                    account_status: findUser.account_status,
                    account_type: findUser.account_type,
                    sub_group: findUser.gc_subgroup ? findUser.gc_subgroup : null,
                    phone: !findUserNumber ? null : findUserNumber.phone,
                    fax_number: !findUserNumber ? null : findUserNumber.fax_number,
                    access: findAccessRights.concat(homePage)
                  }


                  this.$store.commit("LOGGEDIN_USER", loggedInData);
                  this.$router.push("/");
                } 
              }
            }
          }
        }
      }
    },
  },
  watch: {},
  mounted() {},
  created() {
    if(!window.location.search) {
    //For actual testing
      this.getTsuikaUsers().then(() => {
        this.getFullAdmin().then(() => {
          this.getAccessRights();
        })
      })
    } else {
      this.loadMore = true;
      this.getTsuikaUsers().then(() => {
        this.getFullAdmin().then(() => {
          this.getUserNumberData().then(() => {
            this.getSubGroup().then(() => {
              this.getAccessRights().then(() => {
                let urlParams = new URLSearchParams(window.location.search);
                let paramEmail = urlParams.get('email');
                var findUser = this.tsuikaUserData.find(user => user.MailAddress == paramEmail);

                if(!findUser) {
                  this.withoutAccess = true;
                  window.location.href= 'https://gctsuika.hrd-s.com/'

                  this.getFullAdmin().then(() => {
                    this.getAccessRights();
                  })
                } else {
                  const findUserNumber = this.userNumberData.find(userNumber => userNumber.user_id == findUser.UserId);
                  const isFullAdmin = this.fullAdminData.some(fullAdmin => fullAdmin.user_id == findUser.UserId);
                  const homePage = [2]; //meta id for home page
                  const industryCategory = [5];  //meta id for industry category page
                  const orderHistory = [12];  //meta id for industry category page//REVIEW - for hide orderHistory
                  let findAccessRights = [];  //an array for access rights
                  // const gcLeader = [6];  //meta id for gc form page
                  let emailPage = [3]
                  let industryPage =[4]
                  
                  if(!isFullAdmin) {
                    //setting the access rights if the user is not full admin
                    findAccessRights = this.accessRightData.filter(accessRight => {
                      if(accessRight.access_right != 3) {
                        return accessRight.account_type == findUser.account_type && accessRight.sub_group == findUser.gc_subgroup
                      }
                    }).map(m => m.master_id);

                    //show only order history for this group //REVIEW - for hide orderHistory
                    let rights = ["GC Leader", "GC Member"]
                    let newUser = ["nissan-gc@kk-nissan.co.jp"];
                    if(rights.includes(findUser.account_type) || newUser.includes(findUser.MailAddress)) {
                      findAccessRights = findAccessRights.concat(orderHistory,emailPage,industryPage)
                    }
                    

                  }
                  else {
                    findAccessRights = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12] //for restriction in full admin with order history page //REVIEW - for hide orderHistory
                    // findAccessRights = [3, 4, 5, 6, 7, 8, 9, 10, 11] //for restriction in full admin
                  }

                  if(findAccessRights.includes(4)) {
                    findAccessRights = findAccessRights.concat(industryCategory)
                  }

                  // if(findUser.account_type == 'GC Member' || findUser.account_type == 'GC Leader') {
                  //   if(!findAccessRights.includes(6)) {  // 6 is a meta id for gc form page
                  //    findAccessRights = findAccessRights.concat(gcLeader); //adding meta id in access rights array
                  //   }
                  // }


                  /* loggedInData that will pass in local storage for user */

                  var loggedInData = {
                    user_id: findUser.UserId,
                    user_name: findUser.Name,
                    email: findUser.MailAddress,
                    account_status: findUser.account_status,
                    account_type: findUser.account_type,
                    sub_group: findUser.gc_subgroup ? findUser.gc_subgroup : null,
                    phone: !findUserNumber ? null : findUserNumber.phone,
                    fax_number: !findUserNumber ? null : findUserNumber.fax_number,
                    access: findAccessRights.concat(homePage)
                  }


                  this.$store.commit("LOGGEDIN_USER", loggedInData);
                  this.$router.push("/");
                }
              })
            })
          })
        })
      })
    }
  }
};
</script>
<style scoped>
.toolbarColor {
  background-color: #69a1cc;
  background-image: linear-gradient(to right, #5aca92, #4c7cc8);
}
</style>
